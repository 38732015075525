
import { JWTStore } from "@/store/modules";
import { Component, Vue } from "vue-property-decorator";
import LayoutDrawer from "@/components/MobileComponents/LayoutDrawer.vue";

@Component({
  components: {
    LayoutDrawer,
  },
})
export default class AppHeader extends Vue {
  dialogOpen = false;
  payload = {
    username: "",
    password: "",
    isMobile: false,
  };

  isDrawerVisible = false;

  navigateToHome() {
    this.$router.push({
      name: "Home",
    });
  }

  navigateToAbout() {
    this.$router.push({
      name: "About",
    });
  }

  navigateToContact() {
    this.$router.push({
      name: "Contact",
    });
  }

  navigateToDashboard() {
    this.$router.push({
      name: "Dashboard",
    });
  }

  loginModal() {
    this.dialogOpen = true;
  }

  get isAuthenticated() {
    return JWTStore.isAuthenticated;
  }

  async login() {
    let error = false;

    await JWTStore.getJWT(this.payload)
      .then(() => {
        this.dialogOpen = false;
      })
      .catch((err) => {
        error = true;
        console.log("ERROE _> ", err, error);
      });

    if (error) {
      this.$q.notify("Please Provide valid credentials.");
    } else {
      this.$q.notify("Welcome");
    }
  }

  logout() {
    JWTStore.logOut();
  }
}
