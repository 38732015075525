import { render, staticRenderFns } from "./StickyAppHeader.vue?vue&type=template&id=5706e4c4&scoped=true"
import script from "./StickyAppHeader.vue?vue&type=script&lang=ts"
export * from "./StickyAppHeader.vue?vue&type=script&lang=ts"
import style0 from "./StickyAppHeader.vue?vue&type=style&index=0&id=5706e4c4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5706e4c4",
  null
  
)

export default component.exports
import QSlideTransition from 'quasar/src/components/slide-transition/QSlideTransition.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import Ripple from 'quasar/src/directives/Ripple.js';
import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSlideTransition,QHeader,QToolbar,QBtn,QSpace,QTabs,QTab,QMenu,QList,QItem,QItemSection,QRadio,QItemLabel,QSeparator,QAvatar,QDrawer,QScrollArea,QImg,QDialog,QCard,QCardSection,QForm,QInput,QIcon,QCardActions});qInstall(component, 'directives', {Ripple,ClosePopup});
