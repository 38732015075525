
import { MarketStore, NewsStore } from "@/store/modules";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class NewsCard extends Vue {
  show = false;
  itemList = [] as any;
  hideButton = false;

  async created() {
    let routeName = this.$route.name;
    await this.fetchPaginatedNewsList();
    this.paginatedNewsList;

    if (routeName == "Home") {
      if (this.paginatedNewsList.results.length < 4) {
        for (let i = 0; i < this.paginatedNewsList.results.length; i++) {
          this.itemList.push(this.paginatedNewsList.results[i]);
        }
      } else {
        for (let i = 0; i < 4; i++) {
          this.itemList.push(this.paginatedNewsList.results[i]);
        }
      }

      this.hideButton = false;
    }
    if (routeName == "NewsList") {
      this.itemList = this.paginatedNewsList.results;
      this.hideButton = true;
    }
  }

  async fetchPaginatedNewsList() {
    let filters: any = {};

    filters["market"] = MarketStore.MARKETPLACE_ID;
    console.log(filters);
    await NewsStore.getPaginatedNewsList({
      filters: filters,
    });
  }

  get paginatedNewsList() {
    console.log("ns ->", NewsStore.PaginatedNewsList);
    return NewsStore.PaginatedNewsList;
  }

  navigateToNewsDetail(newsId: any) {
    this.$router.push({
      name: "NewsDetail",
      params: {
        id: newsId,
      },
    });
  }

  navigateToNewsListScreen() {
    this.$router.push({
      name: "NewsList",
    });
  }
}
