
import { MarketStore, MMCMembersStore } from "@/store/modules";
import { Component, Vue } from "vue-property-decorator";
import { MMCMembers } from "../../store/model/mmc_members";

@Component
export default class NewsCard extends Vue {
  show = false;
  itemList: any = [];
  hideButton = false;
  async created() {
    console.log("Person created");
    let routeName = this.$route.name;
    await this.fetchMMCMembers();

    if (routeName == "Home") {
      if (this.contactPersons.length < 4) {
        for (let i = 0; i < this.contactPersons.length; i++) {
          this.itemList.push(this.contactPersons[i]);
        }
      } else {
        for (let i = 0; i < 4; i++) {
          this.itemList.push(this.contactPersons[i]);
        }
      }

      this.hideButton = false;
    }
    if (routeName == "Contact") {
      this.itemList = this.contactPersons;
      this.hideButton = true;
    }
  }

  async fetchMMCMembers() {
    let filters: any = {};
    filters["market"] = MarketStore.MARKETPLACE_ID;
    console.log(filters);
    await MMCMembersStore.getMMCMembers({
      filters: filters,
    });
  }

  get contactPersons() {
    // console.log("ns ->", MMCMembersStore.MMCMembers);
    return MMCMembersStore.MMCMembers;
  }

  navigateToContactScreen() {
    this.$router.push({
      name: "Contact",
    });
  }
}
