
import { JWTStore, UiStore } from "@/store/modules";
import { Component, Vue } from "vue-property-decorator";
import LayoutDrawer from "@/components/MobileComponents/LayoutDrawer.vue";
import { eventHub } from "@/utils/eventHub";
import jwt from "@/store/api/jwt";

@Component({
  props: {
    showToolbar: Boolean,
  },
  components: {
    LayoutDrawer,
  },
})
export default class StickyAppHeader extends Vue {
  tab = "home";
  dialogOpen = false;
  payload = {
    username: "",
    password: "",
    isMobile: false,
  };

  langModel = "ne";
  langs = [
    {
      logo: "",
      value: "ne",
      label: "नेपाली",
      color: "red",
    },

    {
      logo: "logo",
      value: "en",
      label: "English",
      color: "blue",
    },
  ];

  isDrawerVisible = false;
  isPwd = true;

  created() {
    this.$i18n.locale = this.langModel;
    this.fetchBasicUserInfo();
    eventHub.$on("toggleDrawerVisibility", this.toggleDrawerVisibility);
  }

  async fetchBasicUserInfo() {
    if (this.isAuthenticated) {
      await JWTStore.getBasicUserInfo();
    }
  }

  navigateToHome() {
    this.$router
      .push({
        name: "Home",
      })
      .catch(() => {
        this.isDrawerVisible = false;
      });
  }

  navigateToAbout() {
    this.$router
      .push({
        name: "About",
      })
      .catch(() => {
        this.isDrawerVisible = false;
      });
  }

  navigateToContact() {
    this.$router
      .push({
        name: "Contact",
      })
      .catch(() => {
        this.isDrawerVisible = false;
      });
  }

  navigateToDashboard() {
    this.$router
      .push({
        name: "Dashboard",
      })
      .catch(() => {
        this.isDrawerVisible = false;
      });
  }

  async toggleDrawerVisibility() {
    // await UiStore.toggleDrawerVisibility()
    let bool = this.isDrawerVisible;
    this.isDrawerVisible = !bool;
  }

  loginModal() {
    this.dialogOpen = true;
  }

  get isAuthenticated() {
    return JWTStore.isAuthenticated;
  }

  get basicUserInfo() {
    return JWTStore.BasicUserInfo;
  }

  async login() {
    let error = false;
    await JWTStore.getJWT(this.payload)
      .then(() => {
        this.dialogOpen = false;
      })
      .catch((err) => {
        error = true;
        console.log("ERROE _> ", err, error);
      });

    this.fetchBasicUserInfo();

    if (error) {
      this.$q.notify("Please Provide valid credentials.");
    } else {
      this.$q.notify("Welcome");
    }
  }

  logout() {
    JWTStore.logOut();
  }
}
