import VueI18n from "@/i18n";

export function nepaliUnicodeNumber(text: string | number | undefined): string {
  if (text || text == 0) {
    text = String(text);
  } else {
    text = "";
  }
  if (VueI18n.locale == "ne") {
    const chars: { [key: string]: string } = {
      "0": "०",
      "1": "१",
      "2": "२",
      "3": "३",
      "4": "४",
      "5": "५",
      "6": "६",
      "7": "७",
      "8": "८",
      "9": "९",
    };
    if (text == "0" || (text && text != null)) {
      return text.toString().replace(/[0123456789]/g, (m) => chars[m]);
    }
  }
  return text;
}
