
import { Vue, Component } from "vue-property-decorator";
import AppHeader from "@/components/UIComponents/AppHeader.vue";
import AppFooter from "@/components/UIComponents/AppFooter.vue";
import StickyAppHeader from "@/components/UIComponents/StickyAppHeader.vue";
@Component({
  components: {
    AppHeader,
    AppFooter,
    StickyAppHeader,
  },

  //   metaInfo(): MetaInfo {
  //     return {
  //       titleTemplate: 'Marketplace Home',
  //       meta: [
  //         { charset: "utf-8" },
  //         { name: "viewport", content: "width=device-width, initial-scale=1" }
  //       ]
  //     };
  //   }
})
export default class Layout extends Vue {
  tab = "home";
  showToolbar = false;

  navigateToHome() {
    this.$router.push({
      name: "Home",
    });
  }

  navigateToAbout() {
    this.$router.push({
      name: "About",
    });
  }

  navigateToContact() {
    this.$router.push({
      name: "Contact",
    });
  }

  onScroll(info: any) {
    if (info.position >= 500) {
      this.showToolbar = true;
    } else {
      this.showToolbar = false;
    }
  }
}
