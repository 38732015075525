
import { MarketStore } from "@/store/modules";
import { Component, Vue } from "vue-property-decorator";

import { nepaliUnicodeNumber } from "@/utils/date";

@Component
export default class Analytics extends Vue {
  created() {
    this.fetchMarketSummary();
  }

  nepaliUnicodeNumber(text: any) {
    return nepaliUnicodeNumber(text);
  }

  async fetchMarketSummary() {
    let market_id = MarketStore.MARKETPLACE_ID;

    // handle date

    let filters: any = {};
    filters["market_id"] = market_id;

    await MarketStore.getMarketSummary({
      filters: filters,
    });
  }

  get marketSummary() {
    return MarketStore.MarketSummary;
  }
}
