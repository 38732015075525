
import { MarketStore, NewsStore, UiStore } from "@/store/modules";
import { Component, Vue } from "vue-property-decorator";

@Component({
  // props:{
  //     isDrawerVisible: Boolean
  // }
})
export default class LayoutDrawer extends Vue {
  // isDrawerVisible = false

  // async created(){
  // }

  navigateToNewsListScreen() {
    this.$router.push({
      name: "NewsList",
    });
  }

  get isDrawerVisible() {
    return UiStore.isDrawerVisible;
  }
}
