
import { Component, Prop, Vue } from "vue-property-decorator";
import { MarketStore } from "@/store/modules";
import { eventHub } from "@/utils/eventHub";
import { Base } from "@/store/api/api";
import { Action } from "@/store/actionType";
import { nepaliUnicodeNumber } from "@/utils/date";
import { ProductPrice } from "@/store/model/market";
@Component
export default class PriceMonitor extends Vue {
  isHomeScreen = true;
  tableLoading = false;
  currentPage: any = "";
  totalPages: any = "";
  commodityCategoryModel = "";
  iFrame = false;

  @Prop({ type: Boolean, default: true })
  commodityFilter!: boolean;

  get columns() {
    return [
      {
        name: "product_name",
        required: true,
        label: `${this.$i18n.t("commodity")}`,
        align: "left",
        field: (row: ProductPrice): string => {
          return this.$i18n.locale == "ne"
            ? row.product_name_ne
              ? row.product_name_ne
              : row.product_name
            : row.product_name;
        },
      },

      {
        name: "wholesale_avg",
        align: "left",
        label: `${this.$i18n.t("avgPrice")} (${this.$i18n.t("Rs.")})`,
        field: "wholesale_avg",
        format: (val: string | number): string =>
          `${this.$i18n.locale == "ne" ? "रू." : "Rs."} ${nepaliUnicodeNumber(
            val
          )}`,
      },
      {
        name: "wholesale_min",
        align: "left",
        label: `${this.$i18n.t("minPrice")} (${this.$i18n.t("Rs.")})`,

        field: "wholesale_min",
        format: (val: string | number): string =>
          `${this.$i18n.locale == "ne" ? "रू." : "Rs."} ${nepaliUnicodeNumber(
            val
          )}`,
      },
      {
        name: "wholesale_max",
        align: "left",
        label: `${this.$i18n.t("maxPrice")} (${this.$i18n.t("Rs.")})`,
        field: "wholesale_max",
        format: (val: string | number): string =>
          `${this.$i18n.locale == "ne" ? "रू." : "Rs."} ${nepaliUnicodeNumber(
            val
          )}`,
      },
    ];
  }
  pagination = {
    rowsPerPage: 0,
  };

  get commodityCategories() {
    const categoryList = JSON.parse(JSON.stringify(MarketStore.CategoryList));
    categoryList.unshift({
      sid: "",
      name: "All",
      name_ne: "सबै",
    });
    return categoryList;
  }

  async created() {
    if (this.$route.name == "ProductPricesIframe") {
      this.$i18n.locale = "ne";
      this.iFrame = true;
    }
    this.fetchPaginatedProductPriceList();
    await MarketStore.getProductCategoryList();

    // assigning button visibility if component appears out of homescreen
    if (this.$route.name != "Home") {
      this.isHomeScreen = false;
    } else {
      this.isHomeScreen = true;
    }

    eventHub.$on(
      "emitPaginatedProductPriceList",
      this.fetchPaginatedProductPriceList
    );
  }

  beforeDestroy() {
    eventHub.$off("emitPaginatedProductPriceList");
  }

  get asOf() {
    if (this.paginatedProductPriceList.results) {
      const productList = this.paginatedProductPriceList.results.filter(
        (x) => x.nep_date && x.nep_date != ""
      );
      if (productList.length > 0) {
        return nepaliUnicodeNumber(productList[0].nep_date);
      }
    }
    return "";
  }

  async fetchPaginatedProductPriceList(
    filteredData: any = null,
    currentPage: any = null
  ) {
    if (this.currentPage == "") {
      this.currentPage = 1;
    }

    let filters: any = {};
    let searchString = "";
    this.tableLoading = true;

    if (filteredData) {
      if (filteredData.product) {
        filters["product"] = filteredData.product.sid;
      }
      if (filteredData.product_category) {
        filters["product__category"] = filteredData.product_category.sid;
      }
      if (filteredData.date) {
        filters["date"] = filteredData.date;
      }
      if (filteredData.search) {
        searchString = filteredData.search;
      }
    }

    if (this.commodityCategoryModel != "") {
      filters["product__category"] = this.commodityCategoryModel;
    }

    console.log("Filters-> ", filters, searchString);

    const marketId = MarketStore.MARKETPLACE_ID;

    const today = new Date();
    const date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    filters["market"] = marketId;
    // filters['date'] = date

    await MarketStore.getPaginatedProductPriceList({
      filters: filters,
      page: this.currentPage,
      searchString: searchString,
    });

    this.paginatedProductPriceList;

    this.tableLoading = false;

    this.totalPages = this.paginatedProductPriceList.total_pages;
    this.currentPage = this.paginatedProductPriceList.page;

    console.log(this.tableLoading);
  }

  get paginatedProductPriceList() {
    return MarketStore.PaginatedProductPriceList;
  }

  // get paginatedProductList() {
  //   const res = MarketStore.PaginatedProductList;
  //   console.log(res);
  //   return res;
  // }

  downloadProductList() {
    const link = document.createElement("a");
    const url = `${Base}/api/${Action.ExportProductPriceList}`;
    link.setAttribute("href", url);
    link.setAttribute("target", "_blank");
    link.click();
  }

  navigateToPriceMonitorScreen() {
    this.$router.push({
      name: "PriceMonitor",
    });
  }
}
